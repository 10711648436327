export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const GET_ACCOUNT_START = "GET_ACCOUNT_START";
export const GET_ACCOUNT_SUCCESS = "GET_ACCOUNT_SUCCESS";
export const GET_ACCOUNT_FAIL = "GET_ACCOUNT_FAIL";
export const CHANGE_PASSWORD_START = "CHANGE_PASSWORD_START";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAIL = "CHANGE_PASSWORD_FAIL";
export const GET_GREENHOUSES_START = "GET_GREENHOUSES_START";
export const GET_GREENHOUSES_SUCCESS = "GET_GREENHOUSES_SUCCESS";
export const GET_GREENHOUSES_FAIL = "GET_GREENHOUSES_FAIL";

export const GET_USERS_START = "GET_USERS_START";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAIL = "GET_USERS_FAIL";

export const GET_TABEL_START = "GET_TABEL_START";
export const GET_TABEL_SUCCESS = "GET_TABEL_SUCCESS";
export const GET_TABEL_FAIL = "GET_TABEL_FAIL";

export const GET_CELLS_START = "GET_CELLS_START";
export const GET_CELLS_SUCCESS = "GET_CELLS_SUCCESS";
export const GET_CELLS_FAIL = "GET_CELLS_FAIL";

export const OPEN_CELLS_START = "OPEN_CELLS_START";
export const OPEN_CELLS_SUCCESS = "OPEN_CELLS_SUCCESS";
export const OPEN_CELLS_FAIL = "OPEN_CELLS_FAIL";

export const ADD_USER_IN_TABEL_START = "ADD_USER_IN_TABEL_START";
export const ADD_USER_IN_TABEL_SUCCESS = "ADD_USER_IN_TABEL_SUCCESS";
export const ADD_USER_IN_TABEL_FAIL = "ADD_USER_IN_TABEL_FAIL";

export const DELETE_USER_IN_TABEL_START = "DELETE_USER_IN_TABEL_START";
export const DELETE_USER_IN_TABEL_SUCCESS = "DELETE_USER_IN_TABEL_SUCCESS";
export const DELETE_USER_IN_TABEL_FAIL = "DELETE_USER_IN_TABEL_FAIL";

export const TRY_SEND_CELLS_START = "TRY_SEND_CELLS_START";
export const TRY_SEND_CELLS_SUCCESS = "TRY_SEND_CELLS_SUCCESS";
export const TRY_SEND_CELLS_FAIL = "TRY_SEND_CELLS_FAIL";

export const GET_ORDERS_START = "GET_ORDERS_START";
export const GET_ORDERS_SUCCESS = "GET_ORDERS_SUCCESS";
export const GET_ORDERS_FAIL = "GET_ORDERS_FAIL";

export const ADD_ORDER_START = "ADD_ORDER_START";
export const ADD_ORDER_SUCCESS = "ADD_ORDER_SUCCESS";
export const ADD_ORDER_FAIL = "ADD_ORDER_FAIL";

export const EDIT_ORDER_START = "EDIT_ORDER_START";
export const EDIT_ORDER_SUCCESS = "EDIT_ORDER_SUCCESS";
export const EDIT_ORDER_FAIL = "EDIT_ORDER_FAIL";

export const DELETE_ORDER_START = "DELETE_ORDER_START";
export const DELETE_ORDER_SUCCESS = "DELETE_ORDER_SUCCESS";
export const DELETE_ORDER_FAIL = "DELETE_ORDER_FAIL";

export const GET_ORDER_PRODUCTS_START = "GET_ORDER_PRODUCTS_START";
export const GET_ORDER_PRODUCTS_SUCCESS = "GET_ORDER_PRODUCTS_SUCCESS";
export const GET_ORDER_PRODUCTS_FAIL = "GET_ORDER_PRODUCTS_FAIL";

export const EDIT_ORDER_PRODUCT_START = "EDIT_ORDER_PRODUCT_START";
export const EDIT_ORDER_PRODUCT_SUCCESS = "EDIT_ORDER_PRODUCT_SUCCESS";
export const EDIT_ORDER_PRODUCT_FAIL = "EDIT_ORDER_PRODUCT_FAIL";

export const DELETE_ORDER_PRODUCT_START = "DELETE_ORDER_PRODUCT_START";
export const DELETE_ORDER_PRODUCT_SUCCESS = "DELETE_ORDER_PRODUCT_SUCCESS";
export const DELETE_ORDER_PRODUCT_FAIL = "DELETE_ORDER_PRODUCT_FAIL";

export const GET_ORDER_LOCATIONS_START = "GET_ORDER_LOCATIONS_START";
export const GET_ORDER_LOCATIONS_SUCCESS = "GET_ORDER_LOCATIONS_SUCCESS";
export const GET_ORDER_LOCATIONS_FAIL = "GET_ORDER_LOCATIONS_FAIL";

export const GET_CATALOGS_START = "GET_CATALOGS_START";
export const GET_CATALOGS_SUCCESS = "GET_CATALOGS_SUCCESS";
export const GET_CATALOGS_FAIL = "GET_CATALOGS_FAIL";

export const CREATE_CATALOG_START = "CREATE_CATALOG_START";
export const CREATE_CATALOG_SUCCESS = "CREATE_CATALOG_SUCCESS";
export const CREATE_CATALOG_FAIL = "CREATE_CATALOG_FAIL";

export const UPDATE_CATALOG_START = "UPDATE_CATALOG_START";
export const UPDATE_CATALOG_SUCCESS = "UPDATE_CATALOG_SUCCESS";
export const UPDATE_CATALOG_FAIL = "UPDATE_CATALOG_FAIL";

export const DELETE_CATALOG_START = "DELETE_CATALOG_START";
export const DELETE_CATALOG_SUCCESS = "DELETE_CATALOG_SUCCESS";
export const DELETE_CATALOG_FAIL = "DELETE_CATALOG_FAIL";


export const UNMOUNT_DOCUMENT = "UNMOUNT_DOCUMENT";

export const GET_DOCUMENT_START = "GET_DOCUMENT_START";
export const GET_DOCUMENT_SUCCESS = "GET_DOCUMENT_SUCCESS";
export const GET_DOCUMENT_FAIL = "GET_DOCUMENT_FAIL";

export const CREATE_DOCUMENT_START = "CREATE_DOCUMENT_START";
export const CREATE_DOCUMENT_SUCCESS = "CREATE_DOCUMENT_SUCCESS";
export const CREATE_DOCUMENT_FAIL = "CREATE_DOCUMENT_FAIL";

export const UPDATE_DOCUMENT_START = "UPDATE_DOCUMENT_START";
export const UPDATE_DOCUMENT_SUCCESS = "UPDATE_DOCUMENT_SUCCESS";
export const UPDATE_DOCUMENT_FAIL = "UPDATE_DOCUMENT_FAIL";

export const DELETE_DOCUMENT_START = "DELETE_DOCUMENT_START";
export const DELETE_DOCUMENT_SUCCESS = "DELETE_DOCUMENT_SUCCESS";
export const DELETE_DOCUMENT_FAIL = "DELETE_DOCUMENT_FAIL";

export const GET_JOB_START = "GET_JOB_START";
export const GET_JOB_SUCCESS = "GET_JOB_SUCCESS";
export const GET_JOB_FAIL = "GET_JOB_FAIL";

export const CREATE_APPLICANT_START = "CREATE_APPLICANT_START";
export const CREATE_APPLICANT_SUCCESS = "CREATE_APPLICANT_SUCCESS";
export const CREATE_APPLICANT_FAIL = "CREATE_APPLICANT_FAIL";