export const GET_CROPS_START = "GET_CROPS_START";
export const GET_CROPS_SUCCESS = "GET_CROPS_SUCCESS";
export const GET_CROPS_FAIL = "GET_CROPS_FAIL";

export const GET_HYBRIDS_START = "GET_HYBRID_START";
export const GET_HYBRIDS_SUCCESS = "GET_HYBRID_SUCCESS";
export const GET_HYBRIDS_FAIL = "GET_HYBRID_FAIL";

export const GET_VEGETATION_PHASES_START = "GET_VEGETATION_PHASES_START";
export const GET_VEGETATION_PHASES_SUCCESS = "GET_VEGETATION_PHASES_SUCCESS";
export const GET_VEGETATION_PHASES_FAIL = "GET_VEGETATION_PHASES_FAIL";

export const GET_OPERATIONS_CATEGORY_START = "GET_OPERATIONS_CATEGORY_START";
export const GET_OPERATIONS_CATEGORY_SUCCESS = "GET_OPERATIONS_CATEGORY_SUCCESS";
export const GET_OPERATIONS_CATEGORY_FAIL = "GET_OPERATIONS_CATEGORY_FAIL";
export const ADD_OPERATION_CATEGORY_START = "ADD_OPERATION_CATEGORY_START";
export const ADD_OPERATION_CATEGORY_SUCCESS = "ADD_OPERATION_CATEGORY_SUCCESS";
export const ADD_OPERATION_CATEGORY_FAIL = "ADD_OPERATION_CATEGORY_FAIL";
export const EDIT_OPERATION_CATEGORY_START = "EDIT_OPERATION_CATEGORY_START";
export const EDIT_OPERATION_CATEGORY_SUCCESS = "EDIT_OPERATION_CATEGORY_SUCCESS";
export const EDIT_OPERATION_CATEGORY_FAIL = "EDIT_OPERATION_CATEGORY_FAIL";
export const DELETE_OPERATION_CATEGORY_START = "DELETE_OPERATION_CATEGORY_START";
export const DELETE_OPERATION_CATEGORY_SUCCESS = "DELETE_OPERATION_CATEGORY_SUCCESS";
export const DELETE_OPERATION_CATEGORY_FAIL = "DELETE_OPERATION_CATEGORY_FAIL";

export const GET_OPERATIONS_FREQUENCY_START = "GET_OPERATIONS_FREQUENCY_START";
export const GET_OPERATIONS_FREQUENCY_SUCCESS = "GET_OPERATIONS_FREQUENCY_SUCCESS";
export const GET_OPERATIONS_FREQUENCY_FAIL = "GET_OPERATIONS_FREQUENCY_FAIL";
export const ADD_OPERATION_FREQUENCY_START = "ADD_OPERATION_FREQUENCY_START";
export const ADD_OPERATION_FREQUENCY_SUCCESS = "ADD_OPERATION_FREQUENCY_SUCCESS";
export const ADD_OPERATION_FREQUENCY_FAIL = "ADD_OPERATION_FREQUENCY_FAIL";
export const EDIT_OPERATION_FREQUENCY_START = "EDIT_OPERATION_FREQUENCY_START";
export const EDIT_OPERATION_FREQUENCY_SUCCESS = "EDIT_OPERATION_FREQUENCY_SUCCESS";
export const EDIT_OPERATION_FREQUENCY_FAIL = "EDIT_OPERATION_FREQUENCY_FAIL";
export const DELETE_OPERATION_FREQUENCY_START = "DELETE_OPERATION_FREQUENCY_START";
export const DELETE_OPERATION_FREQUENCY_SUCCESS = "DELETE_OPERATION_FREQUENCY_SUCCESS";
export const DELETE_OPERATION_FREQUENCY_FAIL = "DELETE_OPERATION_FREQUENCY_FAIL";

export const GET_TECHNOLOGICAL_PERIODS_START = "GET_TECHNOLOGICAL_PERIODS_START";
export const GET_TECHNOLOGICAL_PERIODS_SUCCESS = "GET_TECHNOLOGICAL_PERIODS_SUCCESS";
export const GET_TECHNOLOGICAL_PERIODS_FAIL = "GET_TECHNOLOGICAL_PERIODS_FAIL";
export const ADD_TECHNOLOGICAL_PERIOD_START = "ADD_TECHNOLOGICAL_PERIOD_START";
export const ADD_TECHNOLOGICAL_PERIOD_SUCCESS = "ADD_TECHNOLOGICAL_PERIOD_SUCCESS";
export const ADD_TECHNOLOGICAL_PERIOD_FAIL = "ADD_TECHNOLOGICAL_PERIOD_FAIL";
export const EDIT_TECHNOLOGICAL_PERIOD_START = "EDIT_TECHNOLOGICAL_PERIOD_START";
export const EDIT_TECHNOLOGICAL_PERIOD_SUCCESS = "EDIT_TECHNOLOGICAL_PERIOD_SUCCESS";
export const EDIT_TECHNOLOGICAL_PERIOD_FAIL = "EDIT_TECHNOLOGICAL_PERIOD_FAIL";
export const DELETE_TECHNOLOGICAL_PERIOD_START = "DELETE_TECHNOLOGICAL_PERIOD_START";
export const DELETE_TECHNOLOGICAL_PERIOD_SUCCESS = "DELETE_TECHNOLOGICAL_PERIOD_SUCCESS";
export const DELETE_TECHNOLOGICAL_PERIOD_FAIL = "DELETE_TECHNOLOGICAL_PERIOD_FAIL";

export const GET_OPERATION_STANDARDS_START = "GET_OPERATION_STANDARDS_START";
export const GET_OPERATION_STANDARDS_SUCCESS = "GET_OPERATION_STANDARDS_SUCCESS";
export const GET_OPERATION_STANDARDS_FAIL = "GET_OPERATION_STANDARDS_FAIL";
export const ADD_OPERATION_STANDARD_START = "ADD_OPERATION_STANDARD_START";
export const ADD_OPERATION_STANDARD_SUCCESS = "ADD_OPERATION_STANDARD_SUCCESS";
export const ADD_OPERATION_STANDARD_FAIL = "ADD_OPERATION_STANDARD_FAIL";
export const EDIT_OPERATION_STANDARD_START = "EDIT_OPERATION_STANDARD_START";
export const EDIT_OPERATION_STANDARD_SUCCESS = "EDIT_OPERATION_STANDARD_SUCCESS";
export const EDIT_OPERATION_STANDARD_FAIL = "EDIT_OPERATION_STANDARD_FAIL";
export const DELETE_OPERATION_STANDARD_START = "DELETE_OPERATION_STANDARD_START";
export const DELETE_OPERATION_STANDARD_SUCCESS = "DELETE_OPERATION_STANDARD_SUCCESS";
export const DELETE_OPERATION_STANDARD_FAIL = "DELETE_OPERATION_STANDARD_FAIL";

export const GET_TECHNOLOGICAL_OPERATIONS_START = "GET_TECHNOLOGICAL_OPERATIONS_START";
export const GET_TECHNOLOGICAL_OPERATIONS_SUCCESS = "GET_TECHNOLOGICAL_OPERATIONS_SUCCESS";
export const GET_TECHNOLOGICAL_OPERATIONS_FAIL = "GET_TECHNOLOGICAL_OPERATIONS_FAIL";
export const ADD_TECHNOLOGICAL_OPERATION_START = "ADD_TECHNOLOGICAL_OPERATION_START";
export const ADD_TECHNOLOGICAL_OPERATION_SUCCESS = "ADD_TECHNOLOGICAL_OPERATION_SUCCESS";
export const ADD_TECHNOLOGICAL_OPERATION_FAIL = "ADD_TECHNOLOGICAL_OPERATION_FAIL";
export const EDIT_TECHNOLOGICAL_OPERATION_START = "EDIT_TECHNOLOGICAL_OPERATION_START";
export const EDIT_TECHNOLOGICAL_OPERATION_SUCCESS = "EDIT_TECHNOLOGICAL_OPERATION_SUCCESS";
export const EDIT_TECHNOLOGICAL_OPERATION_FAIL = "EDIT_TECHNOLOGICAL_OPERATION_FAIL";
export const DELETE_TECHNOLOGICAL_OPERATION_START = "DELETE_TECHNOLOGICAL_OPERATION_START";
export const DELETE_TECHNOLOGICAL_OPERATION_SUCCESS = "DELETE_TECHNOLOGICAL_OPERATION_SUCCESS";
export const DELETE_TECHNOLOGICAL_OPERATION_FAIL = "DELETE_TECHNOLOGICAL_OPERATION_FAIL";

export const GET_PRODUCTS_START = "GET_PRODUCTS_START";
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS";
export const GET_PRODUCTS_FAIL = "GET_PRODUCTS_FAIL";
export const ADD_PRODUCT_START = "ADD_PRODUCT_START";
export const ADD_PRODUCT_SUCCESS = "ADD_PRODUCT_SUCCESS";
export const ADD_PRODUCT_FAIL = "ADD_PRODUCT_FAIL";
export const EDIT_PRODUCT_START = "EDIT_PRODUCT_START";
export const EDIT_PRODUCT_SUCCESS = "EDIT_PRODUCT_SUCCESS";
export const EDIT_PRODUCT_FAIL = "EDIT_PRODUCT_FAIL";
export const DELETE_PRODUCT_START = "DELETE_PRODUCT_START";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_FAIL = "DELETE_PRODUCT_FAIL";