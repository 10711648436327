import React, {useEffect, useMemo, useState} from 'react';
import {
    Box,
    CardMedia,
    Typography,
    Grid,
    Button,
    TextField,
    Paper,
    Stack,
    FormControlLabel,
    Checkbox,
    FormControl,
    CardActionArea,
    InputLabel, Select, MenuItem, Slide, Grow,
} from "@mui/material";
import logo from './logo.png'
import InputMask from 'react-input-mask';
import {useDispatch, useSelector} from "react-redux";
import {createApplicant, getJobs} from "../../store/action/rabotaActions";

const Rabota = () => {
    const dispatch = useDispatch()
    const {jobs, error, loading} = useSelector(state => state)

    const [phone, setPhone] = useState('')
    const [name, setName] = useState('')
    const [check, setCheck] = useState(false)
    const [job, setJob] = useState('')
    const [localError, setLocalError] = useState({name: false, phone: false})
    const [sendForm, setSendForm] = useState(false)
    const [endTrans, setEndTrans] = useState(false)

    useEffect(() => {
        dispatch(getJobs())
        setTimeout(() => setEndTrans(true), window.innerWidth < 600 ? 0 : 1300)
    }, [])

    useEffect(() => {
        if (localError.phone && phone.match(/\d+/g)?.reduce((prev, val) => prev + val).length === 11) {
            setLocalError({name: localError.name, phone: false})
        }
    }, [phone])

    useEffect(() => {
        if (localError.name && name.match(/[a-zA-ZА-Яа-я]+/g)?.length > 0) {
            setLocalError({phone: localError.phone, name: false})
        }
    }, [name])

    useEffect(() => {
        if (!loading && sendForm && !error) setTimeout(() => window.location.href = 'https://ecoferma56.ru/', 10000)
    }, [sendForm, loading, error])

    const mJobs = useMemo(() => jobs?.map((j, i) => {
        if (i === 0) setJob(j.id)
        return <MenuItem key={j.id} value={j.id}>{j.name}</MenuItem>
    }), [jobs])

    return (
        <React.Fragment>
            <Box className={'rabota-box'} sx={{height: window.screen.height}}/>
            <Box sx={{overflow: 'auto', height: '100vh'}}>
                <Grid container>
                    <Grid item xs={0} md={2}/>
                    <Grid item xs={0} md={4}>
                        <Slide direction="right" in={true} timeout={1000}>
                            <Typography variant="h1" color={'#f0f0f0'}
                                        sx={{
                                            display: {xs: 'none', md: 'flex'},
                                            mt: 20,
                                            fontWeight: 'bold',
                                        }}>
                                Приходите
                                в нашу
                                команду!
                            </Typography>
                        </Slide>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Grow in={endTrans} timeout={1000}>
                            <Box sx={{m: 2, mt: 6}}>
                                <Stack spacing={2} sx={{width: {xs: 'auto', md: '400px'}}}>
                                    <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                        <CardActionArea
                                            sx={{width: sendForm ? 300 : 200, transition: sendForm ? '1000ms' : 0}}>
                                            <a href={'https://ecoferma56.ru/'}>
                                                <CardMedia
                                                    component="img"
                                                    image={logo}
                                                    alt="ecoferma"
                                                />
                                            </a>
                                        </CardActionArea>
                                    </Box>
                                    {sendForm ? error ? <Typography variant="h4"
                                                                    color={'error.main'}
                                                                    sx={{
                                                                        mt: 20,
                                                                        fontWeight: 'bold',
                                                                        textAlign: 'center'
                                                                    }}>
                                            Извините. Сервис временно не доступен.
                                        </Typography> : <Slide direction="up" in={sendForm} timeout={1000}>
                                            <Typography variant="h4"
                                                        color={'#f0f0f0'}
                                                        sx={{
                                                            mt: 20,
                                                            fontWeight: 'bold',
                                                            textAlign: 'center'
                                                        }}>
                                                Ваша анкета успешно отправлена, с вами скоро свяжутся.
                                            </Typography>
                                        </Slide> :
                                        <React.Fragment>
                                            <Typography variant="h4" color={'#f0f0f0'}
                                                        sx={{textAlign: 'center', fontWeight: 'bold',}}>
                                                Заполните анкету
                                            </Typography>
                                            <Paper>
                                                <TextField
                                                    label={'Имя и фамилия'}
                                                    required
                                                    error={localError.name}
                                                    helperText={localError.name ? 'Поле не может быть пустым' : ''}
                                                    value={name}
                                                    onChange={e => setName(e.target.value)}
                                                    fullWidth
                                                    color={'success'}
                                                    variant={'filled'}/>
                                            </Paper>
                                            <Paper>
                                                <FormControl variant="filled" color={'success'} sx={{width: '100%'}}>
                                                    <InputLabel>Желаемая должность</InputLabel>
                                                    <Select
                                                        value={job}
                                                        onChange={e => setJob(e.target.value)}
                                                    >
                                                        {mJobs}
                                                    </Select>
                                                </FormControl>
                                            </Paper>
                                            <Paper>
                                                <InputMask mask="+7 (999) 999-99-99" maskChar="_" value={phone}
                                                           onChange={e => setPhone(e.target.value)}>
                                                    {() => <TextField
                                                        label={'Номер телефона'}
                                                        fullWidth
                                                        required
                                                        error={localError.phone}
                                                        helperText={localError.phone ? 'Телефон должен соответствовать формату' : ''}
                                                        type={'tel'}
                                                        color={'success'}
                                                        variant={'filled'}/>}
                                                </InputMask>
                                            </Paper>
                                            <FormControlLabel control={
                                                <Checkbox value={check} sx={{color: '#f0f0f0'}}
                                                          onChange={e => setCheck(prev => !prev)}/>
                                            } label={<Typography color={'#f0f0f0'} variant={'subtitle2'}>
                                                Мне есть 18 лет, соглашаюсь на обработку персональных данных
                                                <a href='https://test.ecoferma56.ru/api/media/agreement.pdf'
                                                   target={'_blank'} rel="noopener noreferrer"> подробнее</a>
                                            </Typography>}/>
                                            <Paper sx={{backgroundColor: '#c5c5c5'}}>
                                                <Button variant={'contained'} color={'success'}
                                                        fullWidth
                                                        disabled={!check}
                                                        sx={{fontWeight: 'bold'}}
                                                        onClick={() => {
                                                            const newError = {
                                                                phone: !phone.match(/^(\+7|7|8)?[\s-]?\(?[489][0-9]{2}\)?[\s-]?[0-9]{3}[\s-]?[0-9]{2}[\s-]?[0-9]{2}$/gm),
                                                                name: name.length === 0
                                                            }
                                                            setLocalError(newError)
                                                            if (!newError.name && !newError.phone) {
                                                                setSendForm(true)
                                                                dispatch(createApplicant({
                                                                    name: name,
                                                                    career_objective: job,
                                                                    phone_number: phone
                                                                }))
                                                            }
                                                        }}
                                                        size="large">Отправить</Button>
                                            </Paper>
                                        </React.Fragment>}
                                </Stack>
                            </Box>
                        </Grow>
                    </Grid>
                    <Grid item xs={0} md={2}/>
                </Grid>
            </Box>
        </React.Fragment>
    )
}

export default Rabota;