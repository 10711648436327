import * as actionTypes from "./actionTypes";
import {getApiUrl} from "../../api/urls";
import axios from "axios";


const getJobStart = () => {
    return {
        type: actionTypes.GET_JOB_START
    }
}

const getJobSuccess = (jobs) => {
    return {
        type: actionTypes.GET_JOB_SUCCESS,
        jobs: jobs
    }
}

const getJobFail = (error) => {
    return {
        type: actionTypes.GET_JOB_FAIL,
        error: error
    }
}

const createApplicantStart = () => {
    return {
        type: actionTypes.CREATE_APPLICANT_START
    }
}

const createApplicantSuccess = () => {
    return {
        type: actionTypes.CREATE_APPLICANT_SUCCESS,
    }
}

const createApplicantFail = (error) => {
    return {
        type: actionTypes.CREATE_APPLICANT_FAIL,
        error: error
    }
}


export const getJobs = () => {
    return dispatch => {
        dispatch(getJobStart());
        axios.get(getApiUrl() + 'jobs/').then(res => {
                dispatch(getJobSuccess(res.data));
            }
        ).catch(err => {
            dispatch(getJobFail(err));
        });
    }
}

export const createApplicant = (post) => {
    return dispatch => {
        dispatch(createApplicantStart());
        axios.post(getApiUrl() + 'applicant/', post).then(() => {
                dispatch(createApplicantSuccess());
            }
        ).catch(err => {
            dispatch(createApplicantFail(err));
        });
    }
}