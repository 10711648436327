import './App.less';
import 'simplebar/dist/simplebar.min.css';

import React, {useEffect, useMemo} from 'react'
import {connect} from 'react-redux'
import MainPage from './pages/MainPage/MainPage';
import {Navigate, Route, Routes, useLocation, useNavigate, useSearchParams} from "react-router-dom";
import LoginPage from './pages/LoginPage/LoginPage';
import {authCheckState, logout} from './store/action/authActions';
import CalendarTasks from "./components/CalendarTasks/CalendarTasks";
import RowTasks from "./components/RowTasks/RowTasks";
import Tabel from "./components/Tabel/Tabel";
import ChangePassword from "./pages/LoginPage/ChangePassword";
import {SnackbarProvider} from "notistack";
import Catalog from "./components/Catalog/Catalog";
import Order from "./components/Order/Order";
import Wiki from "./components/Wiki/Wiki";
import OrderCalendar from "./components/NewOrder/OrderCalendar";
import Rabota from "./pages/Rabota/Rabota";
import api from "./api/api";
import {getApiUrl} from "./api/urls";


const App = (props) => {
    const location = useLocation()
    const navigate = useNavigate()
    const [search] = useSearchParams()

    useEffect(() => {
        props.authCheckState()
    }, [])

    const routesOnRules = () => {
        let rules = props.user.rules_template_account
        let route = []
        if (rules.can_make_order) {
            route.push(<Route path="order" element={<Order/>}>
                <Route path=":orderId" element={<Order/>}/>
            </Route>)
        }
        return route
    }

    const routes = useMemo(() => {
        if (props.token === undefined && props.user === undefined) return null
        if (location.pathname === '/remote_auth' || props.is_remote_auth) {
            if (props.token) {
                api.post(getApiUrl() + "remote_auth/", {service: search.get('service')})
                    .then(res => {
                        window.location = search.get('url') + '?token=' + res.data.token
                    })
                    .catch(err => {
                        window.location = search.get('url') + '?error=' + err.response.status
                    })
            } else {
                navigate({pathname: '/login', search: decodeURIComponent(search.toString())}, {replace: true})
            }
            return null
        }
        if (location.pathname === '/logout') {
            props.logout()
            return <Navigate replace to={'login'}/>
        }
        if (props.token === null) {
            return (
                <Routes>
                    <Route path={'api/media/agreement.pdf'} element={<div/>}/>
                    <Route path="rabota" element={<Rabota/>}/>
                    <Route path="login" element={<LoginPage/>}/>
                    <Route path={'*'} element={<Navigate replace to={'login'}/>}/>
                </Routes>
            )
        }
        if (props.user.is_admin || props.user.is_superuser) {
            return (
                <Routes>
                    <Route path={'api/media/agreement.pdf'} element={<div/>}/>
                    <Route path="rabota" element={<Rabota/>}/>
                    <Route exact path="login" element={<Navigate to={'/'}/>}/>
                    <Route exact path="change_password" element={<Navigate to={'/'}/>}/>
                    <Route path="/" element={<MainPage/>}>
                        <Route path="calendar" element={<CalendarTasks/>}/>
                        <Route path="catalog" element={<Catalog/>}/>
                        <Route path="row" element={<RowTasks/>}/>
                        <Route path="tabel" element={<Tabel/>}/>
                        <Route path="order" element={<Order/>}>
                            <Route path=":orderId" element={<Order/>}/>
                        </Route>
                        <Route path="new_order" element={<OrderCalendar/>}>
                            <Route path=":orderId" element={<OrderCalendar/>}/>
                        </Route>
                        <Route path="wiki" element={<Wiki/>}>
                            <Route path=":docId" element={<Wiki/>}/>
                        </Route>
                    </Route>
                </Routes>
            )
        }
        if (props.user.change_password) {
            return (
                <Routes>
                    <Route path={'*'} element={<Navigate replace to={'change_password'}/>}/>
                    <Route exact path="change_password" element={<ChangePassword/>}/>
                </Routes>
            )
        }
        return (
            <Routes>
                <Route exact path="login" element={<Navigate to={'/'}/>}/>
                <Route exact path="change_password" element={<Navigate to={'/'}/>}/>
                <Route path="/" element={<MainPage/>}>
                    <Route path="tabel" element={<Tabel/>}/>
                    {routesOnRules()}
                </Route>
            </Routes>
        )
    }, [props.token, props.user, location])

    return (
        <SnackbarProvider maxSnack={3}>
            <div className="App">
                {routes}
            </div>
        </SnackbarProvider>
    )
}

const mapStateToProps = (state) => ({
    token: state.token,
    user: state.user,
    error: state.error,
    is_remote_auth: state.is_remote_auth,
})

const mapDispatchToProps = dispatch => ({
    authCheckState: () => dispatch(authCheckState()),
    logout: () => dispatch(logout())
})

export default connect(mapStateToProps, mapDispatchToProps)(App)